<script>
    import LeaveComment from './LeaveComment.vue';
    import CommentListItem from './CommentListItem.vue';
    // import OpenGraphContent from './OpenGraphContent.vue';
    import ClubActivityContainer from './ModuleContainers/ClubActivity.vue';
    import DeletedPostContent from './DeletedPostContent.vue';
    import { timeAgoFromNow } from '../../methods/utils';

    export default {
        name: 'time-line-item',
        props: {
            id: { type: Number, required: true },
            content: { type: String, required: true },
            nameSurname: { type: String, required: true },
            canLeaveComment: { type: Boolean, required: true },
            profilePhoto: { type: String, required: true },
            ownProfilePhoto: { type: String, required: true },
            ownNameSurname: { type: String, required: true },
            ownPost: { type: Boolean, required: true },
            realDate: { type: String, required: true },
            realDateFormatted: { type: String, required: true },
            likes: { type: Number, required: true },
            comments: {type: Number, required: true},
            likeList: {type: Array},
            liked: {type: Boolean, required: true},
            pinned: {type: Boolean, default: false},
            hashList: {type: Array, required: true},
            og: {type: Object, required: false},
            referenceContent: {type: Object, required: false},
            url: {type: String, required: false},
            userTypeDir: {type: String, required: true},
            moduleId: {type: Number, required: true},
            referenceId: {type: Number, required: true},
            userType: {type: Number, required: true},
        },
        data() {
            return {
                mLiked: this.liked,
                mComments: this.comments,
                commentList: [],
                commentListLoaded: false,
                commentListPending: false,
                showLeaveComment: false,
                trueLikes: this.liked ? this.likes - 1 : this.likes,
                likeButtonPending: false,
                loadLikesButtonPending: false,
                moduleBadges: {
                    15: {
                        text: 'Kulüp',
                        icon: 'md md-flag'
                    }
                },
                videoLink: false,
            }
        },
        methods: {
            likePost() {
                if (!this.likeButtonPending) {
                    callAjax(null,{
                        url: `obsapi/timeline/likePost?moduleId=${this.moduleId}&referenceId=${this.referenceId}&postId=${this.id}&newState=${!this.mLiked}`,
                        method: 'GET',
                        context: this,
                        noYtLoader: true
                    },function (obj, scope) {
                        scope.mLiked = !scope.mLiked;
                        scope.likeButtonPending = false;
                    },function (scope) {
                        scope.likeButtonPending = true;
                    });
                }
            },
            loadComments(callback) {
                if (!this.commentListLoaded && !this.commentListPending && this.comments > 0) {
                    this.commentListPending = true;

                    callAjax(null,{
                        url: `obsapi/timeline/loadCommentList?moduleId=${this.moduleId}&referenceId=${this.referenceId}&postId=${this.id}`,
                        method: 'GET',
                        context: this
                    },function (obj, scope) {
                        scope.commentListLoaded = true;
                        scope.commentListPending = false;
                        scope.commentList = _.values(obj.data);

                        if (typeof callback === "function") {
                            callback(scope);
                        }
                    });
                }
            },
            setShowLeaveComment() {
                if (!this.commentListLoaded && !this.commentListPending && this.comments > 0) {
                    this.loadComments((scope) => {
                        scope.showLeaveComment = true;
                    });
                } else {
                    this.showLeaveComment = true;
                }
            },
            leaveComment(comment) {
                callAjax(null,{
                    url: `obsapi/timeline/leaveComment?moduleId=${this.moduleId}&referenceId=${this.referenceId}&postId=${this.id}&comment=${comment}`,
                    method: 'GET',
                    context: this
                },function (obj, scope) {
                    scope.commentList.unshift(obj.data);
                    scope.mComments += 1;
                });
            },
            deletePost() {
                if (this.ownPost) {
                    if (confirm('Bu gönderinizi silmek istediğinizden emin misiniz?')) {
                        callAjax(null,{
                            url: `obsapi/timeline/deletePost?postId=${this.id}`,
                            method: 'GET',
                            context: this
                        },function (obj, scope) {
                            scope.$emit('deletePostInTimeLine', scope.id);
                        });
                    }
                }
            },
            deleteCommentInCommentList(id) {
                const commentItemPosition = this.commentList.map((x) => {
                    return x.id;
                }).indexOf(id);

                this.commentList.splice(commentItemPosition, 1);
                this.mComments -= 1;
            },
            showLikedUsers() {
                if (!this.loadLikesButtonPending) {
                    callAjax(null,{
                        url: `obsapi/timeline/loadPostLikeList?moduleId=${this.moduleId}&referenceId=${this.referenceId}&postId=${this.id}`,
                        method: 'GET',
                        context: this
                    },function (obj, scope) {
                        modalstrap.customDialog({
                            size: 'md',
                            title: '<i class="md md-thumb-up"></i> Beğeniler',
                            message: '<ul class="list divider-full-bleed" id="post-likes-list"></ul>',
                            closeButton: true,
                            container: $("#inner-page"),
                            modalConfig: {
                                backdrop: true,
                                keyboard: true
                            },
                            buttons: [
                                {
                                    tag: 0,
                                    label: "Kapat",
                                    dismiss: true,
                                    className: "btn-default"
                                }
                            ],
                            init: function ($modal) {
                                $modal.find('.modal-header').addClass('style-primary');

                                let userTypeName;
                                const $template = $('<li class="tile">' +
                                    '<a class="tile-content ink-reaction">' +
                                    '<div class="tile-icon">' +
                                    '<img class="profilePhoto">' +
                                    '</div>' +
                                    '<div class="tile-text">' +
                                    '<div class="nameSurname"></div>' +
                                    '<small class="userTypeName"></small>' +
                                    '</div>' +
                                    '</a>' +
                                    '</li>');

                                $.each(obj.data, function (i, val) {
                                    let $clone = $template.clone();

                                    if (val.ownLike) {
                                        $clone.addClass('style-default-light');
                                        userTypeName = 'Siz';
                                    } else {
                                        switch (val.userType) {
                                            case 1: userTypeName = 'Öğrenci'; break;
                                            case 2: userTypeName = 'Veli'; break;
                                            case 3: userTypeName = 'Öğretmen'; break;
                                            case 4: userTypeName = 'Sistem yöneticisi'; break;
                                            case 5: userTypeName = 'Yönetici'; break;
                                            case 6: userTypeName = 'Muhasebe'; break;
                                            default: userTypeName = ''; break
                                        }
                                    }

                                   // let pf = val.profilePhoto.indexOf('downloadFileByToken') === -1 ? val.profilePhoto : 'obsapi/' + val.profilePhoto;

                                    $clone.find('.nameSurname').text(val.nameSurname);
                                    $clone.find('.userTypeName').text(userTypeName);
                                    $clone.find('.profilePhoto').attr('src', val.profilePhoto).attr('alt', val.nameSurname);
                                    $clone.appendTo('ul#post-likes-list');
                                });
                            }
                        });
                        scope.loadLikesButtonPending = false;
                    },function (scope) {
                        scope.loadLikesButtonPending = true;
                    });
                }
            },
            pinPost() {
                if (this.ownPost) {
                    if (confirm('Bu gönderiyi sayfaya sabitlemek istediğinizden emin misiniz? Önceki sabitlenen gönderiler normale dönecek.')) {
                        callAjax(null,{
                            url: `obsapi/timeline/pinPost?postId=${this.id}&pin=true`,
                            method: 'GET',
                            context: this
                        },function (obj, scope) {
                            scope.pinned = true;
                            scope.$emit('pinPostInTimeLine', scope.id);
                        });
                    }
                }
            },
            unpinPost() {
                if (this.ownPost) {
                    if (confirm('Bu gönderiyi sayfadan ayırmak istiyor musunuz?')) {
                        callAjax(null,{
                            url: `obsapi/timeline/pinPost?postId=${this.id}&pin=false`,
                            method: 'GET',
                            context: this
                        },function (obj, scope) {
                            scope.pinned = false;
                        });
                    }
                }
            },
            escapeHTML(text) {
                return text
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;");
            },
            URLTextToAnchor(text) {
                const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                return text.replace(exp, "<a target='_blank' href='$1'>$1</a>");
            },
            timeAgoFromNow,
            contentHasVideo() {
                const video = this.content.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/) ||
                    this.content.match(/(?:http|https)?:?\/?\/?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/)
                if (video) {
                    this.videoLink = video[0];
                }
                return video !== null;
            }
        },
        computed: {
            likeText() {
                return this.mLiked ? 'Beğendin' : 'Beğen';
            }
        },
        components: {
            LeaveComment,
            CommentListItem,
            // OpenGraphContent,
            ClubActivityContainer,
            DeletedPostContent
        },
        created() {
            this.mLiked = this.liked;
        }
    }
</script>

<template>
    <div class="card style-default-bright margin-bottom-xl">
        <div class="card-body">
            <div class="row">
                <div class="col-xs-12" id="profile-photo-section">
                    <img class="img-circle img-responsive pull-left width-1" :src="profilePhoto" :alt="nameSurname">
                </div>
                <div class="col-xs-12" id="post-info-section">
                    <span class="text-primary text-lg hover-underline" id="name-surname">{{nameSurname}}</span>
                    <div id="create-date">
                        <i class="md md-timelapse time-icon cursor-pointer opacity-50" style="margin-bottom: -0.1em;" data-toggle="tooltip" data-placement="top" :data-original-title="realDateFormatted"></i>
                        <span class="opacity-50">{{timeAgoFromNow(realDate)}}</span>
                        <template v-if="moduleId != 0">
                            <span class="opacity-50"> | <i :class="moduleBadges[moduleId].icon" style="margin-bottom: -0.1em;"></i> {{moduleBadges[moduleId].text}}</span>
                            <span class="opacity-50"> paylaşımı</span>
                        </template>
                        <span class="opacity-50" v-if="pinned"> | <i class="glyphicon glyphicon-pushpin"></i> Sabitlenmiş gönderi</span>
                    </div>
                </div>
                <div class="btn-group width-1" v-if="ownPost">
                    <a class="btn btn-icon-toggle dropdown-toggle" data-toggle="dropdown">
                        <i class="md md-keyboard-arrow-down"></i>
                    </a>
                    <ul class="dropdown-menu animation-dock pull-right menu-card-styling post-actions" role="menu"
                        style="text-align: left;">
                        <li>
                            <a @click.prevent="deletePost" class="cursor-pointer"><i
                                class="md md-delete text-danger"></i> Gönderiyi kaldır</a>
                            <a @click.prevent="pinPost" v-if="!pinned && moduleId == 0" class="cursor-pointer"><i
                                class="glyphicon glyphicon-pushpin text-default" style="vertical-align: middle;"></i>
                                Gönderiyi sabitle</a>
                            <a @click.prevent="unpinPost" v-else-if="pinned && moduleId == 0" class="cursor-pointer"><i
                                class="md md-cancel text-default"></i> Sabitlemeyi kaldır</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="text-selectable"
                id="post-content"
                v-html="URLTextToAnchor(escapeHTML(videoLink ? content.replace(videoLink, '') : content))">
            </div>
            <div class="video-container" v-if="contentHasVideo()">
                <video-embed v-bind:src="videoLink"></video-embed>
            </div>
            <deleted-post-content v-if="referenceContent === null"/>
            <club-activity-container
                v-if="referenceContent"
                :id="referenceContent.id"
                :title="referenceContent.title"
                :description="referenceContent.description"
                :startDate="referenceContent.startDate"
                :endDate="referenceContent.endDate"
                :hashList="referenceContent.hashList"
                :realDateFormatted="realDateFormatted"
            >
            </club-activity-container>
            <div
                    class="post-image cursor-pointer"
                    v-else-if="hashList.length > 0"
            >
                <div
                    :class="hashList.length === 1 ? 'display-none' : ''"
                >
                    <i
                        class="md md-photo-library"
                        data-toggle="tooltip"
                        data-placement="top"
                        :data-original-title="hashList.length + ' adet görsel'"
                        style="font-size: 35px; position: absolute; margin-left: 10px; color: #fff; text-shadow: 2px 2px black;"
                    ></i>
                </div>
                <img
                    :class="index === 0 ? 'object-fit' : 'display-none'"
                    v-for="(hash, index) in hashList"
                    v-img="{ group: 'group-' + id }"
                    :src="'obsapi/public/downloadFileByToken?f=' + hash + '&view'"
                    :alt="realDateFormatted"
                >
            </div>
            <!--            <open-graph-content-->
            <!--                    v-else-if="og"-->
            <!--                    :og="og"-->
            <!--                    :url="url"-->
            <!--            />-->
            <hr class="no-margin">
            <div v-if="userType !== 5">
                <button class="btn btn-xs btn-primary" v-if="mLiked" :disabled="likeButtonPending" @click="likePost">
                    <i class="md md-thumb-up post-action-icon"></i>
                    {{likeText}}
                </button>
                <button class="btn btn-xs btn-flat btn-primary" v-else :disabled="likeButtonPending" @click="likePost">
                    <i class="md md-thumb-up post-action-icon"></i>
                    {{likeText}}
                </button>
                <button
                    class="btn btn-xs btn-flat btn-primary"
                        v-if="canLeaveComment"
                        @click="setShowLeaveComment">Yorum Yap</button>
                <span id="comment-count" class="text-primary hover-underline" v-if="mComments > 0" @click="loadComments">{{mComments}} yorum</span>
            </div>
            <div v-if="userType !== 5">
                <template v-if="trueLikes === 0 && !mLiked"></template>
                <template v-else-if="trueLikes === 0 && mLiked">Bunu beğendin.</template>
                <template v-else-if="trueLikes > 0 && mLiked">Sen ve diğer <span class="text-primary hover-underline"
                                                                                 @click="showLikedUsers">{{trueLikes}} kişi</span>
                    bunu beğendiniz.
                </template>
                <template v-else-if="trueLikes > 0"><span class="text-primary hover-underline" @click="showLikedUsers">{{trueLikes}} kişi</span>
                    bunu beğendi.
                </template>
            </div>
        </div>
        <div class="card-actionbar" v-if="userType !== 5">
            <leave-comment
                :ownProfilePhoto="ownProfilePhoto"
                :ownNameSurname="ownNameSurname"
                v-on:leaveComment="leaveComment($event)"
                v-if="(commentList.length > 0 || showLeaveComment) && canLeaveComment"
            />
            <div class="card-actionbar-row" v-if="commentList.length > 0">
                <comment-list-item
                    v-for="item in commentList"
                    :key="item.id"
                        :id="item.id"
                        :comment="item.comment"
                        :profilePhoto="item.profilePhoto"
                        :nameSurname="item.nameSurname"
                        :ownComment="item.ownComment"
                        :userTypeDir="userTypeDir"
                        :moduleId="moduleId"
                        :referenceId="referenceId"
                        v-on:deleteCommentInCommentList="deleteCommentInCommentList($event)"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .card-actionbar {
        padding-bottom: 0;
    }

    .card-body {
        padding: 12px 12px 6px 12px;
    }

    .btn {
        text-transform: capitalize;
    }

    #name-surname {
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
        padding: 5px 0 5px 0;
    }

    #create-date {
        font-size: 11px;
        line-height: 11px;
        padding-bottom: 10px;
    }

    #profile-photo-section {
        width: 64px;
        height: 40px;
    }

    #post-info-section {
        width: calc(100% - 128px);
        padding-left: 0;
    }

    #post-content {
        white-space: pre-wrap;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #comment-count {
        float: right;
    }

    .post-action-icon {
        vertical-align: top;
    }

    .time-icon {
        vertical-align: middle;
    }

    .card-actionbar-row {
        background: #f6f7f9;
        text-align: left;
    }

    .post-image {
        height: 250px;
    }

    .object-fit {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ul.post-actions li i {
        vertical-align: top;
    }
</style>

<style>
    #post-content a {
        color: darkorange !important;
    }
</style>
